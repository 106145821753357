import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { FaCog } from "react-icons/fa";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//components
import i18n from '../i18n';
import { ButtonFull } from '../components/Buttons';
//context
import UserContext from '../context/UserContext';
//util
import { colors, months } from '../util/values';
import { commonStyles } from '../util/styles';
//api
import aws from '../api/aws';

const PreferencesScreen = ({ }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const { state } = useLocation();
  const languages = [{ code: "en", value: "English" }, { code: "fr", value: "Français" }];
  const [oldLanguage, setOldLanguage] = useState(languages[0].code);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].code);
  //const [selectedLanguageString, setSelectedLanguageString] = useState(languages[0].value);
  const [email, setEmail] = useState("");
  const [isHover, setIsHover] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [nameRecipesFr, setNameRecipesFr] = useState("");
  const [nameSubRecipesFr, setNameSubRecipesFr] = useState("");
  const [nameIngredientsFr, setNameIngredientsFr] = useState("");
  const [nameRecipesEn, setNameRecipesEn] = useState("");
  const [nameSubRecipesEn, setNameSubRecipesEn] = useState("");
  const [nameIngredientsEn, setNameIngredientsEn] = useState("");
  const [financialYearMonth, setFinancialYearMonth] = useState(0);
  const [financialYearDay, setFinancialYearDay] = useState(1);
  const [days, setDays] = useState([]);

  useEffect(() => {
    setEmail(user[0].email ? user[0].email : "");
    let nbOfDays = (new Date((new Date()).getFullYear(), financialYearMonth + 1, 0)).getDate();
    changeDays(nbOfDays);
    const financialYear = JSON.parse(localStorage.getItem('financialYear'));
    setFinancialYearMonth(financialYear && financialYear.financial_month != null ? financialYear.financial_month : 0);
    setFinancialYearDay(financialYear && financialYear.financial_day != null ? financialYear.financial_day : 1);
    getLang();
    getNavNames();
  }, []);

  useEffect(() => {
    let nbOfDays = (new Date((new Date()).getFullYear(), financialYearMonth + 1, 0)).getDate();
    changeDays(nbOfDays);
    if (nbOfDays < financialYearDay) {
      setFinancialYearDay(nbOfDays);
      changeFinancialYear(financialYearMonth, nbOfDays);
    }

  }, [financialYearMonth]);

  const handleMouseEnter = (button) => {
    setIsHover(button);
  };

  const handleMouseLeave = () => {
    setIsHover("");
  };

  const changeDays = (nbOfDays) => {
    let newDays = [];
    for (let i = 1; i <= nbOfDays; i++) {
      newDays.push(i);
    }
    setDays(newDays);
  };

  const getNavNames = async () => {
    let navNames = localStorage.getItem('nav');
    if (navNames != null) {
      navNames = JSON.parse(navNames);
      setNameRecipesFr(navNames.fr.recipes !== null && navNames.fr.recipes != "" ? navNames.fr.recipes : t("routeMyRecipes", { lng: 'fr' }));
      setNameSubRecipesFr(navNames.fr.subRecipes !== null && navNames.fr.subRecipes != "" ? navNames.fr.subRecipes : t("routeMySubRecipes", { lng: 'fr' }));
      setNameIngredientsFr(navNames.fr.ingredients !== null && navNames.fr.ingredients != "" ? navNames.fr.ingredients : t("routeMyIngredients", { lng: 'fr' }));
      setNameRecipesEn(navNames.en.recipes !== null && navNames.en.recipes != "" ? navNames.en.recipes : t("routeMyRecipes", { lng: 'en' }));
      setNameSubRecipesEn(navNames.en.subRecipes !== null && navNames.en.subRecipes != "" ? navNames.en.subRecipes : t("routeMySubRecipes", { lng: 'en' }));
      setNameIngredientsEn(navNames.en.ingredients !== null && navNames.en.ingredients != "" ? navNames.en.ingredients : t("routeMyIngredients", { lng: 'en' }));
    }
  };

  const getLang = async () => {
    let lang = localStorage.getItem("appLanguage");
    lang = lang ? lang : 'en';
    //const lang = i18n.language;
    //setOldLanguage(i18n.language);
    changLang(languages[languages.findIndex(o => o.code === lang)].value);
  };

  const changLang = (string) => {
    const i = languages.findIndex(o => o.value === string);
    //setSelectedLanguageString(languages[i].value);
    setSelectedLanguage(languages[i].code);
    i18n.changeLanguage(languages[i].code);
    localStorage.setItem("appLanguage", languages[i].code);
  };

  const changNavNames = async () => {
    let newNavNames = {
      en: {
        recipes: nameRecipesEn !== "" ? nameRecipesEn : t("routeMyRecipes", { lng: 'en' }),
        subRecipes: nameSubRecipesEn !== "" ? nameSubRecipesEn : t("routeMySubRecipes", { lng: 'en' }),
        ingredients: nameIngredientsEn !== "" ? nameIngredientsEn : t("routeMyIngredients", { lng: 'en' })
      },
      fr: {
        recipes: nameRecipesFr !== "" ? nameRecipesFr : t("routeMyRecipes", { lng: 'fr' }),
        subRecipes: nameSubRecipesFr !== "" ? nameSubRecipesFr : t("routeMySubRecipes", { lng: 'fr' }),
        ingredients: nameIngredientsFr !== "" ? nameIngredientsFr : t("routeMyIngredients", { lng: 'fr' })
      }
    };
    localStorage.setItem("nav", JSON.stringify(newNavNames));
    i18n.changeLanguage(selectedLanguage);

    await aws.put('/user', { id: user[0].user_id, action: 'nav', navNames: newNavNames });
  };

  const changeFinancialYear = async (month, day) => {
    const financialYear = JSON.parse(localStorage.getItem('financialYear'));
    if (!financialYear || financialYear.financial_month !== month || financialYear.financial_day !== day) {
      localStorage.setItem("financialYear", JSON.stringify({ financial_month: month, financial_day: day }));
      await aws.put('/user', { id: user[0].user_id, action: 'financial', month: month, day: day });
    }
  };


  return (
    <div style={commonStyles.container}>
      <div style={{ ...commonStyles.title, display: 'flex', alignItems: 'center', marginBottom: 60 }}>
        <FaCog size="1em" color={colors.main} />
        <div style={{ marginLeft: 10 }}>{t(state.title)}</div>
      </div>
      <div style={{ ...commonStyles.body, maxWidth: 800 }}>
        <div style={styles.settingContainer}>
          <div style={styles.settingLabel}>{t("lblLanguage")}</div>
          <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <div style={{ ...styles.languageButton, cursor: isHover === "fr" ? 'pointer' : 'auto' }} onClick={() => changLang("Français")}
              onMouseEnter={() => handleMouseEnter("fr")} onMouseLeave={handleMouseLeave}>
              <div style={selectedLanguage === "fr" ? styles.languageTextSelected : styles.languageText}>{t('languageFrench')}</div>
            </div>
            <div style={{ ...styles.languageButton, cursor: isHover === "en" ? 'pointer' : 'auto' }} onClick={() => changLang("English")}
              onMouseEnter={() => handleMouseEnter("en")} onMouseLeave={handleMouseLeave}>
              <div style={selectedLanguage === "en" ? styles.languageTextSelected : styles.languageText}>{t('languageEnglish')}</div>
            </div>
            <div style={styles.languageButton}></div>
          </div>
        </div>
        <div style={styles.settingContainer}>
          <div style={styles.settingLabel}>{t("lblEmail2")}</div>
          <div style={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => window.open("mailto:" + email, '_blank')}>
            <div style={styles.link}>{email}</div>
          </div>
        </div>
        <div style={{ ...styles.settingContainer, marginBottom: 20 }}>
          <div style={styles.settingLabel}>{t('lblNavNames')}</div>
          <ButtonFull text={t('btnConfirm')} color={colors.main} onButtonClick={() => changNavNames()} />
        </div>
        <div style={styles.settingContainer}>
          <table style={styles.table}>
            <tbody>
              <tr>
                <th style={styles.headerCell}>{t('languageFrench')}</th>
                <th style={styles.headerCell}></th>
                <th style={styles.headerCell}>{t('languageEnglish')}</th>
              </tr>
              <tr>
                <td style={styles.cell}>
                  <TextField sx={{ input: { ...styles.cellText, color: colors.main }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
                    id="recipeFr" variant="standard" value={nameRecipesFr} onChange={(event) => setNameRecipesFr(event.target.value)}
                  />
                </td>
                <td style={{ width: 20 }}></td>
                <td style={styles.cell}>
                  <TextField sx={{ input: { ...styles.cellText, color: colors.main }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
                    id="recipeEn" variant="standard" value={nameRecipesEn} onChange={(event) => setNameRecipesEn(event.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ ...styles.cellText, paddingBottom: 20 }}>{t("descriptionRecipes")}</td>
              </tr>
              <tr>
                <td style={styles.cell}>
                  <TextField sx={{ input: { ...styles.cellText, color: colors.main }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
                    id="recipeFr" variant="standard" value={nameSubRecipesFr} onChange={(event) => setNameSubRecipesFr(event.target.value)}
                  />
                </td>
                <td style={{ width: 20 }}></td>
                <td style={styles.cell}>
                  <TextField sx={{ input: { ...styles.cellText, color: colors.main }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
                    id="recipeEn" variant="standard" value={nameSubRecipesEn} onChange={(event) => setNameSubRecipesEn(event.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ ...styles.cellText, paddingBottom: 20 }}>{t("descriptionSubRecipes")}</td>
              </tr>
              <tr>
                <td style={styles.cell}>
                  <TextField sx={{ input: { ...styles.cellText, color: colors.main }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
                    id="recipeFr" variant="standard" value={nameIngredientsFr} onChange={(event) => setNameIngredientsFr(event.target.value)}
                  />
                </td>
                <td style={{ width: 20 }}></td>
                <td style={styles.cell}>
                  <TextField sx={{ input: { ...styles.cellText, color: colors.main }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
                    id="recipeEn" variant="standard" value={nameIngredientsEn} onChange={(event) => setNameIngredientsEn(event.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ ...styles.cellText, paddingBottom: 20 }}>{t("descriptionIngredients")}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={styles.settingContainer}>
          <div style={styles.settingLabel}>{t("lblFinancialYear")}</div>
          <Select sx={styles.unit} variant="standard" value={financialYearDay} onChange={(event) => { setFinancialYearDay(event.target.value); changeFinancialYear(financialYearMonth, event.target.value) }}>
            {days.map(item => <MenuItem key={item + "day"} value={item}>{t(item)}</MenuItem>)}
          </Select>
          <div style={{ width: 20 }} />
          <Select sx={styles.unit} variant="standard" value={financialYearMonth} onChange={(event) => { setFinancialYearMonth(event.target.value); changeFinancialYear(event.target.value, financialYearDay) }}>
            {months[i18n.language].map((item, index) => <MenuItem key={item} value={index}>{t(item)}</MenuItem>)}
          </Select>
        </div>
      </div>
    </div>
  );
};

const styles = {
  settingContainer: {
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
    marginBottom: 60
  },
  settingLabel: {
    flexGrow: 0,
    marginRight: 20,
    fontSize: 20,
    color: colors.text
  },
  languageButton: {
    flexGrow: 1
  },
  languageText: {
    fontSize: 20,
    textAlign: 'center',
    color: colors.text
  },
  languageTextSelected: {
    fontSize: 20,
    textAlign: 'center',
    color: colors.main,
    textDecorationLine: 'underline'
  },
  link: {
    fontSize: 20,
    color: colors.link,
    paddingLeft: 8
  },

  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px'
  },
  headerCell: {
    paddingBottom: 20,
    textAlign: 'left',
    fontWeight: 400,
    fontSize: 20,
    color: colors.text
  },
  cell: {
    padding: '10px 0px',
    width: 100
  },
  cellText: {
    fontSize: 20,
    color: colors.text
  },

  unit: {
    margin: 0,
    fontFamily: 'Inter',
    //width: '100%',
    fontSize: 15,
    fontWeight: 400,
    color: colors.textNegative,
    textAlign: 'center',
    backgroundColor: colors.main,
    borderRadius: 2.5,
    transition: 'background-color 0.1s linear',
    '&:hover': { backgroundColor: colors.darkGray },
    '&:before': { border: 'none' },
    '&:after': { border: 'none' },
    '&:not(.Mui-disabled):hover::before': { border: 'none' },
    '.MuiSelect-icon': { display: 'none' },
    '.MuiSelect-select': { padding: '5px 20px !important', width: '100%' }
  }
};

export default PreferencesScreen;

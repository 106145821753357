import i18n from '../i18n';
import { colorValuesRecipes, colors, completeUnits, massUnits, volumeUnits, liquidUnits, volumesUnits, colorValues, allUnits, completeUnitsWithPortion } from './values';

export const exportJson = () => { };

let count = 0;

export const getCount = () => {
  return count++;
};

export const UTCToLocal = (date) => {
  let year = parseInt(date.slice(0, 4));
  let month = parseInt(date.slice(5, 7));
  let day = parseInt(date.slice(8, 10));
  return new Date(year, month - 1, day, 5, 5, 5);
  //return new Date(date.slice(0, 19));
};

export const UTCToLocal2 = (date) => {
  date = (new Date(date)).toISOString();
  let year = parseInt(date.slice(0, 4));
  let month = parseInt(date.slice(5, 7));
  let day = parseInt(date.slice(8, 10));
  return new Date(year, month - 1, day, 0, 0, 0);
};

//receives an array of objects, each object needs to have the properties: co2, unit, quantity, density and unitWeight
export const calculateCo2FromYield = (items) => {
  let totalCo2 = 0;
  for (let i = 0; i < items.length; i++) {
    if (massUnits[items[i].unit]) {
      totalCo2 += items[i].quantity * massUnits[items[i].unit] * items[i].co2 / items[i].yield_kg;
    } else if (volumesUnits[items[i].unit]) {
      totalCo2 += items[i].quantity * volumesUnits[items[i].unit] * items[i].co2 / items[i].yield_l;
    } else if (items[i].unit === "unit") {
      totalCo2 += items[i].quantity * items[i].co2 / items[i].yield_unit;
    } else if (items[i].unit === "portions") {
      totalCo2 += items[i].quantity * items[i].co2 / items[i].portions;
    } else if (items[i].unit === "...") {
      totalCo2 += 0;
    } else if (items[i].quantity && items[i].co2) {
      totalCo2 += (items[i].quantity * items[i].co2);
    } else {
      totalCo2 += items[i].co2 ? items[i].co2 : 0;
    }
  }
  return totalCo2;
};

export const co2ByYield = (co2, item) => {
  let totalCo2 = 0;
  if (item.yield_kg !== null) {
    totalCo2 += co2 / item.yield_kg;
  } else if (item.yield_l !== null) {
    totalCo2 += co2 / item.yield_l;
  } else if (item.yield_unit !== null) {
    totalCo2 += co2 / item.yield_unit;
  } else if (item.portions !== 0) {
    totalCo2 += co2 / item.portions;
  }
  return totalCo2;
};

export const getColor = (totco2, servings, thresholdHigh, thresholdLow) => {
  if (totco2 === null) {
    return colors.lightGrey;
  } else if (servings == null || servings === 0) {
    return colors.good;
  }
  return totco2 / servings > thresholdHigh ? colors.bad : totco2 / servings > thresholdLow ? colors.warning : colors.good;
};

//Group items like [{ing}, {ing}] to [{title, [{ing}, {ing}]}, {title, [{ing}, {ing}]}]
export const groupItems = (data, sort) => {
  let sortedItems = [{ uniqueId: -1, title: "Other", data: [] }];
  let groups = { "Other": 0 };

  for (let i = 0; i < data.length; i++) {
    if (data[i].groupName && data[i].groupName !== "" && groups[data[i].groupName] === undefined) {
      groups[data[i].groupName] = sortedItems.length;
      sortedItems.push({ uniqueId: getCount(), title: data[i].groupName, data: [] });
    }

    if (!data[i].groupName || data[i].groupName === "") {
      sortedItems[0].data.push(data[i]);
    } else {
      sortedItems[groups[data[i].groupName]].data.push(data[i]);
    }
  }

  if (sort) {
    sortedItems = sortedItems.sort((a, b) => {
      if (a.title === "Other") {
        return -1;
      } else if (b.title === "Other") {
        return 1;
      } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      } else if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  sortedItems[0].title = "";
  if (sortedItems[0].data.length === 0) {
    sortedItems.splice(0, 1);
  }
  return sortedItems;
};

//Will create 2 arrays on to add new ingredients and one to update existing ingredients in the shopping list.
export const treatDataShoplist = (data) => {
  const toAdd = [];
  const toUpdate = [];

  for (let i = 0; i < data.length; i++) {
    if (data[i].shoplist_id) {
      toUpdate.push({ shoplistId: data[i].shoplist_id, quantity: convertUnit(data[i].unit, data[i].shoplist_unit, data[i].quantity, data[i].density, data[i].unit_weight) });
    } else {
      toAdd.push({ id: data[i].ingredient_id, name: data[i].ingredient_name, quantity: (data[i].quantity).toFixed(2), unit: data[i].unit });
    }
  }
  return [toAdd, toUpdate];
};

export const convertUnit = (originalUnit, targetUnit, quantity, density, unitWeight) => {
  return (fromKgToAny(targetUnit, fromAnyToKg(originalUnit, quantity, density, unitWeight), density, unitWeight)).toFixed(6);
};

const fromAnyToKg = (originalUnit, quantity, density, unitWeight) => {
  const massUnitsArray = Object.keys(massUnits);
  const volumeUnitsArray = Object.keys(volumeUnits);
  const liquidUnitsArray = Object.keys(liquidUnits);
  let returnValue = 0;
  if (massUnitsArray.includes(originalUnit)) {
    returnValue = quantity * massUnits[originalUnit];
  } else if (volumeUnitsArray.includes(originalUnit)) {
    returnValue = quantity * volumeUnits[originalUnit] * density;
  } else if (liquidUnitsArray.includes(originalUnit)) {
    returnValue = quantity * liquidUnits[originalUnit] * density;
  } else if (originalUnit === 'unit') {
    returnValue = quantity * unitWeight;
  }
  return returnValue;
};

/*export const fromAnyToKg2 = (originalUnit, quantity, yieldKg, yieldL, yieldUnit) => {
  let returnValue = 0;
  if (massUnits[originalUnit]) {
    returnValue = yieldKg ? quantity * massUnits[originalUnit] * yieldKg / yieldKg : NaN;
  } else if (volumesUnits[originalUnit]) {
    returnValue = yieldL ? quantity * volumesUnits[originalUnit] * yieldKg / yieldL : NaN;
  } else if (originalUnit === 'unit') {
    returnValue = yieldUnit ? quantity * yieldKg / yieldUnit : NaN;
  }
  return returnValue;
};

export const fromAnyToL2 = (originalUnit, quantity, yieldKg, yieldL, yieldUnit) => {
  let returnValue = 0;
  if (massUnits[originalUnit]) {
    returnValue = yieldKg ? quantity * massUnits[originalUnit] * yieldL / yieldKg : NaN;
  } else if (volumesUnits[originalUnit]) {
    returnValue = yieldL ? quantity * volumesUnits[originalUnit] * yieldL / yieldL : NaN;
  } else if (originalUnit === 'unit') {
    returnValue = yieldUnit ? quantity * yieldL / yieldUnit : NaN;
  }
  return returnValue;
};

export const fromAnyToUnit2 = (originalUnit, quantity, yieldKg, yieldL, yieldUnit) => {
  let returnValue = 0;
  if (massUnits[originalUnit]) {
    returnValue = yieldKg ? quantity * massUnits[originalUnit] * yieldUnit / yieldKg : NaN;
  } else if (volumesUnits[originalUnit]) {
    returnValue = yieldL ? quantity * volumesUnits[originalUnit] * yieldUnit / yieldL : NaN;
  } else if (originalUnit === 'unit') {
    returnValue = yieldUnit ? quantity * yieldUnit / yieldUnit : NaN;
  }
  return returnValue;
};*/

export const fromAnyToKg2 = (originalUnit, quantity, yieldKg, yieldL, yieldUnit, yieldPortions) => {
  let returnValue = 0;
  if (massUnits[originalUnit]) {
    returnValue = yieldKg ? quantity * massUnits[originalUnit] * yieldKg / yieldKg : NaN;
  } else if (volumesUnits[originalUnit]) {
    returnValue = yieldL ? quantity * volumesUnits[originalUnit] * yieldKg / yieldL : NaN;
  } else if (originalUnit === 'unit') {
    returnValue = yieldUnit ? quantity * yieldKg / yieldUnit : NaN;
  } else if (originalUnit === 'portions') {
    returnValue = yieldPortions ? quantity * yieldKg / yieldPortions : NaN;
  }
  return returnValue;
};

export const fromAnyToL2 = (originalUnit, quantity, yieldKg, yieldL, yieldUnit, yieldPortions) => {
  let returnValue = 0;
  if (massUnits[originalUnit]) {
    returnValue = yieldKg ? quantity * massUnits[originalUnit] * yieldL / yieldKg : NaN;
  } else if (volumesUnits[originalUnit]) {
    returnValue = yieldL ? quantity * volumesUnits[originalUnit] * yieldL / yieldL : NaN;
  } else if (originalUnit === 'unit') {
    returnValue = yieldUnit ? quantity * yieldL / yieldUnit : NaN;
  } else if (originalUnit === 'portions') {
    returnValue = yieldPortions ? quantity * yieldL / yieldPortions : NaN;
  }
  return returnValue;
};

export const fromAnyToUnit2 = (originalUnit, quantity, yieldKg, yieldL, yieldUnit, yieldPortions) => {
  let returnValue = 0;
  if (massUnits[originalUnit]) {
    returnValue = yieldKg ? quantity * massUnits[originalUnit] * yieldUnit / yieldKg : NaN;
  } else if (volumesUnits[originalUnit]) {
    returnValue = yieldL ? quantity * volumesUnits[originalUnit] * yieldUnit / yieldL : NaN;
  } else if (originalUnit === 'unit') {
    returnValue = yieldUnit ? quantity * yieldUnit / yieldUnit : NaN;
  } else if (originalUnit === 'portions') {
    returnValue = yieldPortions ? quantity * yieldUnit / yieldPortions : NaN;
  }
  return returnValue;
};

export const fromAnyToPortions2 = (originalUnit, quantity, yieldKg, yieldL, yieldUnit, yieldPortions) => {
  let returnValue = 0;
  if (massUnits[originalUnit]) {
    returnValue = yieldKg ? quantity * massUnits[originalUnit] * yieldPortions / yieldKg : NaN;
  } else if (volumesUnits[originalUnit]) {
    returnValue = yieldL ? quantity * volumesUnits[originalUnit] * yieldPortions / yieldL : NaN;
  } else if (originalUnit === 'unit') {
    returnValue = yieldUnit ? quantity * yieldPortions / yieldUnit : NaN;
  } else if (originalUnit === 'portions') {
    returnValue = yieldPortions ? quantity * yieldPortions / yieldPortions : NaN;
  }
  return returnValue;
};

const fromKgToAny = (targetUnit, quantity, density, unitWeight) => {
  const massUnitsArray = Object.keys(massUnits);
  const volumeUnitsArray = Object.keys(volumeUnits);
  const liquidUnitsArray = Object.keys(liquidUnits);
  let returnValue = 0;
  if (massUnitsArray.includes(targetUnit)) {
    returnValue = quantity / massUnits[targetUnit];
  } else if (volumeUnitsArray.includes(targetUnit)) {
    returnValue = quantity / (volumeUnits[targetUnit] * density);
  } else if (liquidUnitsArray.includes(targetUnit)) {
    returnValue = quantity / (liquidUnits[targetUnit] * density);
  } else if (targetUnit === 'unit') {
    returnValue = quantity / unitWeight;
  }
  return returnValue;
};

export const cutAndRoundNumber = (nb) => {
  let nbAmount = 0;
  if (nb < 10) {
    nbAmount = 2;
  } else if (nb < 100) {
    nbAmount = 1
  }
  return parseFloat(nb.toFixed(nbAmount));
};

export const selectUnits = (ingredient) => {
  let mass = [];
  let volume = [];
  let liquid = [];
  let single = [];
  if (ingredient.mass) {
    mass = [...Object.keys(massUnits)];
  }
  if (ingredient.volume) {
    volume = [...Object.keys(volumeUnits)];
  }
  if (ingredient.liquid) {
    liquid = [...Object.keys(liquidUnits)];
  }
  if (ingredient.single) {
    single = ["unit"];
  }
  return [...mass, ...volume, ...liquid, ...single];
};

export const selectUnits2 = (ingredient) => {
  let objArray = [];
  for (let obj of completeUnits) {
    if (ingredient.mass && obj.category === "mass") {
      objArray.push(obj);
    }
    if (ingredient.volume && obj.category === "volume") {
      objArray.push(obj);
    }
    if (ingredient.liquid && obj.category === "liquid") {
      objArray.push(obj);
    }
    if (ingredient.single && obj.category === "single") {
      objArray.push(obj);
    }
  }
  return objArray;
};

export const sortBy = (field, ascending, divideByPortions, primer) => {
  let key = null;
  if (primer && divideByPortions) {
    key = (x) => primer(x[field]) / primer(x.portions);
  } else if (primer) {
    key = (x) => primer(isNaN(x[field]) ? 100000000 : x[field]);
  } else if (field === 'dateNull') {
    key = (x) => x['co2'] === null ? '00' : x['date'];
  } else {
    key = (x) => x[field].toLowerCase();
  }
  ascending = ascending ? 1 : -1;

  return function (a, b) {
    return a = key(a), b = key(b), ascending * ((a > b) - (b > a));
  }
}

export const is1For1Ing = (ing) => {
  let is1For1 = false;
  let other1For1 = {
    kg: { value: null, is1For1: false },
    l: { value: null, is1For1: false },
    unit: { value: null, is1For1: false },
    portions: { value: null, is1For1: false },
  };

  if (ing.composition && ing.composition.length === 1) {
    let unit = getUnit(ing.composition[0].unit);
    if (ing.yield_kg != null && ing.yield_kg !== 0 && unit) {
      let yieldKg = fromAnyToKg2(unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions);
      other1For1.kg.value = +yieldKg.toFixed(4);
      other1For1.kg.is1For1 = ing.yield_kg.toFixed(4) === yieldKg.toFixed(4);
      if (massUnits[unit])
        is1For1 = other1For1.kg.is1For1;
    }
    if (ing.yield_l != null && ing.yield_l !== 0 && unit) {
      let yieldL = fromAnyToL2(unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions);
      other1For1.l.value = +yieldL.toFixed(4);
      other1For1.l.is1For1 = ing.yield_l.toFixed(4) === yieldL.toFixed(4);
      if (volumesUnits[unit])
        is1For1 = other1For1.l.is1For1;
    }
    if (ing.yield_unit != null && ing.yield_unit !== 0 && unit) {
      let yieldUnit = fromAnyToUnit2(unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions);
      other1For1.unit.value = +yieldUnit.toFixed(4);
      other1For1.unit.is1For1 = ing.yield_unit.toFixed(4) === yieldUnit.toFixed(4);
      if (unit === 'unit')
        is1For1 = other1For1.unit.is1For1;
    }
    if (ing.portions != null && ing.portions !== 0 && unit) {
      let yieldPortions = fromAnyToPortions2(unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions);
      other1For1.portions.value = +yieldPortions.toFixed(4);
      other1For1.portions.is1For1 = ing.portions.toFixed(4) === yieldPortions.toFixed(4);
      if (unit === 'portions')
        is1For1 = other1For1.portions.is1For1;
    }
  }
  return [is1For1, other1For1];
};

export const is1For1WithUnit = (subItem, mainItem) => {
  let is1For1 = false;
  if (mainItem.yield_kg !== null) {
    is1For1 = mainItem.yield_kg === fromAnyToKg2(subItem.unit, subItem.quantity, subItem.yield_kg, subItem.yield_l, subItem.yield_unit);
  } else if (mainItem.yield_l !== null) {
    is1For1 = mainItem.yield_l === fromAnyToL2(subItem.unit, subItem.quantity, subItem.yield_kg, subItem.yield_l, subItem.yield_unit);
  } else if (mainItem.yield_unit !== null) {
    is1For1 = mainItem.yield_unit === fromAnyToUnit2(subItem.unit, subItem.quantity, subItem.yield_kg, subItem.yield_l, subItem.yield_unit);
  } else if (mainItem.portions !== 0) {
    is1For1 = mainItem.portions === subItem.quantity * subItem.portions;
  }
  return is1For1;
};

export const getIngredientCo2 = (itemList, mainItem) => {
  let totalCo2 = 0;
  let counterOfSubIngredients = 0;
  let association = null;
  let is1For1 = false;
  for (let i = 0; i < itemList.length; i++) {
    if (itemList[i].main_ingredient_id === mainItem.ingredient_id) {
      if (itemList[i].owner === null) {
        counterOfSubIngredients++;
        association = itemList[i];
      }
      if (itemList[i].co2 !== null) {
        totalCo2 += calculateCo2FromYield([itemList[i]]);
        if (itemList[i].owner === null && is1For1WithUnit(itemList[i], mainItem)) {
          is1For1 = true;
        }
      } else if (itemList[i].co2 === null) {
        let co2 = getIngredientCo2(itemList, itemList[i]);
        itemList[i].co2 = co2;
        totalCo2 += calculateCo2FromYield([itemList[i]]);
        if (itemList[i].owner === null && is1For1WithUnit(itemList[i], mainItem)) {
          is1For1 = true;
        }
      }
    }
  }
  if (association !== null && mainItem.owner !== null && counterOfSubIngredients === 1 && is1For1) {
    mainItem.association = i18n.language === "fr" ? association.name_fra : association.name_eng;
    mainItem.alternative = association.alternative;
    mainItem.alternative2 = association.alternative2;
    mainItem.alternative3 = association.alternative3;
    mainItem.alternative4 = association.alternative4;
    mainItem.alternative5 = association.alternative5;
    mainItem.altCo2 = association.altCo2;
    mainItem.altCo22 = association.altCo22;
    mainItem.altCo23 = association.altCo23;
    mainItem.altCo24 = association.altCo24;
    mainItem.altCo25 = association.altCo25;
    mainItem.altErnIngCode = association.altErnIngCode;
    mainItem.altErnIngCode2 = association.altErnIngCode2;
    mainItem.altErnIngCode3 = association.altErnIngCode3;
    mainItem.altErnIngCode4 = association.altErnIngCode4;
    mainItem.altErnIngCode5 = association.altErnIngCode5;
    mainItem.altRegion = association.altRegion;
    mainItem.altRegion2 = association.altRegion2;
    mainItem.altRegion3 = association.altRegion3;
    mainItem.altRegion4 = association.altRegion4;
    mainItem.altRegion5 = association.altRegion5;
    mainItem.alternativeId = association.alternativeId;
    mainItem.alternativeId2 = association.alternativeId2;
    mainItem.alternativeId3 = association.alternativeId3;
    mainItem.alternativeId4 = association.alternativeId4;
    mainItem.alternativeId5 = association.alternativeId5;
  }
  return totalCo2;
};

export const getIng = (itemList, mainItem, needAlt = true) => {
  let ing = {
    ...mainItem,
    composition: [],
    isAutoKg: false,
    isAutoL: false,
    isAutoUnit: false,
    isAutoPortions: false,
    uniqueId: getCount()
  };
  for (let i = 0; i < itemList.length; i++) {
    if (itemList[i].main_ingredient_id !== null && itemList[i].main_ingredient_id === ing.ingredient_id) {
      ing.composition.push(getIng(itemList, itemList[i], needAlt));
    }
  }
  if (ing.composition.length === 1) {
    if (ing.yield_kg === -1) {
      ing.yield_kg = fromAnyToKg2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions);
      ing.isAutoKg = true;
    }
    if (ing.yield_l === -1) {
      ing.yield_l = fromAnyToL2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions);
      ing.isAutoL = true;
    }
    if (ing.yield_unit === -1) {
      ing.yield_unit = fromAnyToUnit2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions);
      ing.isAutoUnit = true;
    }
    if (ing.portions === -1) {
      ing.portions = fromAnyToPortions2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yield_kg, ing.composition[0].yield_l, ing.composition[0].yield_unit, ing.composition[0].portions);
      ing.isAutoPortions = true;
    }
  }
  if (ing.co2 === null) {
    ing.co2 = calculateCo2FromYield(ing.composition);
  }
  if (needAlt && ing.composition.length === 1 && ing.owner !== null && is1For1Ing(ing) && ing.composition[0].owner === null) {
    const association = ing.composition[0];
    ing.association = i18n.language === "fr" ? association.name_fra : association.name_eng;
    ing.alternative = association.alternative;
    ing.alternative2 = association.alternative2;
    ing.alternative3 = association.alternative3;
    ing.alternative4 = association.alternative4;
    ing.alternative5 = association.alternative5;
    ing.altCo2 = association.altCo2;
    ing.altCo22 = association.altCo22;
    ing.altCo23 = association.altCo23;
    ing.altCo24 = association.altCo24;
    ing.altCo25 = association.altCo25;
    ing.altErnIngCode = association.altErnIngCode;
    ing.altErnIngCode2 = association.altErnIngCode2;
    ing.altErnIngCode3 = association.altErnIngCode3;
    ing.altErnIngCode4 = association.altErnIngCode4;
    ing.altErnIngCode5 = association.altErnIngCode5;
    ing.altRegion = association.altRegion;
    ing.altRegion2 = association.altRegion2;
    ing.altRegion3 = association.altRegion3;
    ing.altRegion4 = association.altRegion4;
    ing.altRegion5 = association.altRegion5;
    ing.alternativeId = association.alternativeId;
    ing.alternativeId2 = association.alternativeId2;
    ing.alternativeId3 = association.alternativeId3;
    ing.alternativeId4 = association.alternativeId4;
    ing.alternativeId5 = association.alternativeId5;
  }
  return ing;
};

export const formatNumber = (number) => {
  let str = number.toFixed(3) + "";
  let result;
  if (str.length > 4 && str.includes('.')) {
    result = str.substring(0, 5);
  } else {
    result = str.substring(0, 4);
  }
  return +result;
};

export const calculateGraph = (items, menu, category, isGraph) => {
  let thresholdLow = 0;
  let thresholdHigh = 0;
  let average = 0;
  let dataNumber = 0;
  for (let item of items) {
    if (item.menusArray.includes(menu)) {
      let categoryName = i18n.language === 'fr' ? item.cat_name_fr : item.cat_name_en;
      if (categoryName !== null && (category === "offer" || categoryName === category)) {
        average += item.co2 / item.portions;
        dataNumber++;
        thresholdLow += item.threshold_low !== null ? item.threshold_low : colorValuesRecipes.ingredientLow;
        thresholdHigh += item.threshold_high !== null ? item.threshold_high : colorValuesRecipes.ingredientHigh;
      }
    }

  }
  if (dataNumber > 0) {
    average /= dataNumber;
    thresholdLow /= dataNumber;
    thresholdHigh /= dataNumber;
  }

  let isGreen = average < thresholdLow;
  let isYellow = average >= thresholdLow && average < thresholdHigh;
  let isRed = average >= thresholdHigh;

  let tickValues = [];
  let dataGreen, dataYellow, dataRed;
  if (isGraph) {
    let continueWhile = true;
    for (let i = 0.5; continueWhile; i += 0.5) {
      tickValues.push(i);
      if ((isRed && i > average) || (!isRed && i > thresholdHigh)) {
        continueWhile = false;
      }
    }

    dataGreen = [
      { x: 1, co2: isGreen ? average : 0, isHere: isGreen },
      { x: 2, co2: thresholdLow },
    ];
    dataYellow = [
      { x: 1, co2: isYellow ? average : 0, isHere: isYellow },
      { x: 2, co2: thresholdHigh - thresholdLow }
    ];
    dataRed = [
      { x: 1, co2: isRed ? average : 0, isHere: isRed },
      { x: 2, co2: (tickValues.length > 0 ? tickValues[tickValues.length - 1] : 3) - thresholdHigh, isHere: true }
    ];
  }

  return { menu, category, impact: average, thresholdLow, thresholdHigh, tickValues, dataGreen, dataYellow, dataRed };
};

export const getMonthDate = (date) => {
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  let day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  return date.getFullYear() + "-" + month + "-" + day;
};

export const getDateFromString = (date) => {
  return new Date(date.substring(0, 4), date.substring(5, 7) - 1, date.substring(8, 10));
};

export const getMonthDateShort = (date) => {
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return month + "/" + date.getFullYear().toString().substring(2);
};

export const getWeekNumber = (weeks, date) => {
  for (let w of weeks) {
    if (date.getTime() < w.dateEnd && date.getTime() >= w.dateStart) {
      return w.value;
    }
  }
  return "0";
};

export const getFinancialYear = (years, date) => {
  for (let y of years) {
    if (date.getTime() < y.dateEnd && date.getTime() >= y.dateStart) {
      return y.value;
    }
  }
  return 0;
};

export const icon_HSV = (color) => {
  return `
  <svg viewBox="0 0 81.03 29.03">
      <path d="M79.85,22.17c-.19-.28-.39-.57-.61-.86L79,21l-.49-.62-.26-.32c-.22-.27-.45-.54-.7-.81l-.33-.35-.59-.62-.43-.43-.55-.52-.48-.44c-.32-.29-.65-.58-1-.87h0C69.77,12.36,63,8.43,52.35,4.94A75.3,75.3,0,0,0,45,3h0A141.75,141.75,0,0,0,21.43.15h0L19.78.09h-.12L16.91,0H14.07L12.4,0H0V29H72.87S84.74,29.42,79.85,22.17ZM50.79,9.69A72.61,72.61,0,0,1,67,17H48a3,3,0,0,1-3-3V8.09C47.06,8.57,49,9.1,50.79,9.69ZM5,7H25a3,3,0,0,1,3,3v4a3,3,0,0,1-3,3H5Z" transform="translate(0 0)" fill="${color}"/>
      <g style="isolation:isolate">
          <path d="M26.83,26.52l1.5-7.16h1l-.62,3h3.71l.62-3h1l-1.49,7.16h-1l.7-3.37H28.5l-.71,3.38Z" transform="translate(0 0)" fill="#fff"/>
          <path d="M34.34,24.21l.94-.09v.25a1.54,1.54,0,0,0,.19.76,1.25,1.25,0,0,0,.63.53,2.65,2.65,0,0,0,1,.19,2,2,0,0,0,1.31-.38,1.1,1.1,0,0,0,.45-.86.92.92,0,0,0-.24-.62,4.54,4.54,0,0,0-1.33-.74,9.86,9.86,0,0,1-1.15-.56,2.29,2.29,0,0,1-.71-.69,1.59,1.59,0,0,1-.23-.85,1.72,1.72,0,0,1,.3-1,1.88,1.88,0,0,1,.89-.68,3.51,3.51,0,0,1,1.32-.23,3.35,3.35,0,0,1,1.47.29,1.95,1.95,0,0,1,1.14,1.71s0,.08,0,.15l-.92.07a1.66,1.66,0,0,0-.05-.47,1.25,1.25,0,0,0-.3-.5,1.45,1.45,0,0,0-.56-.33,2.41,2.41,0,0,0-.8-.12,1.88,1.88,0,0,0-1.22.35.87.87,0,0,0-.33.71.84.84,0,0,0,.14.47,1.41,1.41,0,0,0,.49.41q.25.14,1.21.56a8.33,8.33,0,0,1,1.06.54,1.88,1.88,0,0,1,.6.63,1.66,1.66,0,0,1,.21.84,1.79,1.79,0,0,1-.35,1.07,2.28,2.28,0,0,1-1,.76,3.62,3.62,0,0,1-1.43.27,3.42,3.42,0,0,1-2-.53A2.16,2.16,0,0,1,34.34,24.21Z" transform="translate(0 0)" fill="#fff"/>
          <path d="M40.77,26.52l1.5-7.16h3a4.62,4.62,0,0,1,1.35.15,1.31,1.31,0,0,1,.72.56,1.89,1.89,0,0,1,.26,1A2,2,0,0,1,47,22.56a3.14,3.14,0,0,1-1.85.75,2.73,2.73,0,0,1,.59.57,6.42,6.42,0,0,1,.72,1.3L47,26.52H46l-.51-1.33a6.27,6.27,0,0,0-.63-1.25,1.28,1.28,0,0,0-.5-.48,2.16,2.16,0,0,0-.83-.12H42.39l-.66,3.18Zm1.78-4h1.32q.92,0,1.2,0A2.31,2.31,0,0,0,46,22.3a1.38,1.38,0,0,0,.54-.51,1.34,1.34,0,0,0,.19-.69,1,1,0,0,0-.14-.54.72.72,0,0,0-.37-.31,2.51,2.51,0,0,0-.78-.08H43.06Z" transform="translate(0 0)" fill="#fff"/>
      </g>
  </svg>
  `;
};

export const icon_TGV = (color) => {
  return `
  <svg viewBox="0 0 81.03 29.03">
      <path d="M79.85,22.17c-.19-.28-.39-.57-.61-.86L79,21l-.49-.62-.26-.32c-.22-.27-.45-.54-.7-.81l-.33-.35-.59-.62-.43-.43-.55-.52-.48-.44c-.32-.29-.65-.58-1-.87h0C69.77,12.36,63,8.43,52.35,4.94A75.3,75.3,0,0,0,45,3h0A141.75,141.75,0,0,0,21.43.15h0L19.78.09h-.12L16.91,0H14.07L12.4,0H0V29H72.87S84.74,29.42,79.85,22.17ZM50.79,9.69A72.61,72.61,0,0,1,67,17H48a3,3,0,0,1-3-3V8.09C47.06,8.57,49,9.1,50.79,9.69ZM5,7H25a3,3,0,0,1,3,3v4a3,3,0,0,1-3,3H5Z" transform="translate(0 0)" fill="${color}"/>
      <g style="isolation:isolate">
          <path d="M28.92,26.52l1.32-6.34H27.89l.17-.81H33.7l-.17.81H31.2l-1.32,6.34Z" transform="translate(0 0)" fill="#fff"/>
          <path d="M36.85,23.7l.17-.81h3.13l-.61,2.91a5.64,5.64,0,0,1-1.35.61,5,5,0,0,1-1.49.23,2.84,2.84,0,0,1-2.33-.94,3.13,3.13,0,0,1-.65-2,4.87,4.87,0,0,1,.57-2.35,3.87,3.87,0,0,1,1.44-1.56,3.76,3.76,0,0,1,1.93-.51,3.24,3.24,0,0,1,1.37.28,2.22,2.22,0,0,1,.93.74,3.1,3.1,0,0,1,.46,1.2l-.94.1a1.9,1.9,0,0,0-.63-1.13A1.86,1.86,0,0,0,37.63,20a2.68,2.68,0,0,0-1.45.42,2.93,2.93,0,0,0-1.08,1.28,4.47,4.47,0,0,0-.4,1.94,2.26,2.26,0,0,0,.53,1.63,2,2,0,0,0,1.47.55,4.07,4.07,0,0,0,2-.62L39,23.7Z" transform="translate(0 0)" fill="#fff"/>
          <path d="M43.15,26.52l-1.38-7.16h.92l.83,4.27q.23,1.16.3,1.88.45-.92.74-1.46l2.55-4.69h1l-3.93,7.16Z" transform="translate(0 0)" fill="#fff"/>
      </g>
  </svg>
  `;
};

export const getUnit = (unit) => {
  for (let c of completeUnits) {
    if (c.others.includes(unit)) {
      return c.name;
    }
  }
  return false;
};

export const toLowerNoAccents = (text) => {
  return text.normalize("NFD").replace(/\p{Diacritic}/gu, "").replaceAll("’", "'").toLowerCase();
};

export const changeDateFrom = (date, dateTo, periodList, tab, setDateFrom, setDateTo) => {
  let gap = tab === "lblBy4Weeks" ? 12 : 11;
  if (date != null && date !== '') {
    let indexFrom = null;
    let indexTo = null;
    for (let i = 0; i < periodList.length; i++) {
      if (date.value === periodList[i].value) {
        indexFrom = i;
      }
      if (dateTo.value === periodList[i].value) {
        indexTo = i;
      }
    }

    if (tab === 'lblByItem' || tab === 'lblByMenuItem') {
      setDateFrom(periodList[indexFrom === null ? periodList.length - 1 : indexFrom]);
      setDateTo(periodList[indexTo === null ? 0 : indexTo]);
    } else {
      let newIndexTo = indexTo;
      if (indexFrom - indexTo > gap) {
        newIndexTo = indexFrom - gap < 0 ? 0 : indexFrom - gap;
      }
      setDateFrom(periodList[indexFrom === null ? periodList.length - 1 : indexFrom]);
      setDateTo(periodList[indexTo === null ? (periodList.length - 1 - gap < 0 ? 0 : periodList.length - 1 - gap) : newIndexTo]);
    }
  } else {
    setDateFrom(periodList[tab === 'lblByItem' || tab === 'lblByMenuItem' || periodList.length < gap ? periodList.length - 1 : gap]);
    setDateTo(periodList[0]);
  }
};

export const changeDateTo = (date, dateFrom, periodList, tab, setDateFrom, setDateTo) => {
  let gap = tab === "lblBy4Weeks" ? 12 : 11;
  if (date != null && date !== '') {
    let indexFrom = null;
    let indexTo = null;
    for (let i = 0; i < periodList.length; i++) {
      if (dateFrom.value === periodList[i].value) {
        indexFrom = i;
      }
      if (date.value === periodList[i].value) {
        indexTo = i;
      }
    }

    if (tab === 'lblByItem' || tab === 'lblByMenuItem') {
      setDateFrom(periodList[indexFrom === null ? periodList.length - 1 : indexFrom]);
      setDateTo(periodList[indexTo === null ? 0 : indexTo]);
    } else {
      let newIndexFrom = indexFrom;
      if (indexFrom - indexTo > gap) {
        newIndexFrom = indexTo + gap > periodList.length - 1 ? periodList.length - 1 : indexTo + gap;
      }
      setDateFrom(periodList[indexFrom === null ? (0 + gap > periodList.length - 1 ? periodList.length - 1 : 0 + gap) : newIndexFrom]);
      setDateTo(periodList[indexTo === null ? 0 : indexTo]);
    }
  } else {
    setDateFrom(periodList[tab === 'lblByItem' || tab === 'lblByMenuItem' || periodList.length < gap ? periodList.length - 1 : gap]);
    setDateTo(periodList[0]);
  }
};

var toString = Object.prototype.toString;
export const deepCopy = (obj) => {
  var rv;

  switch (typeof obj) {
    case "object":
      if (obj === null) {
        // null => null
        rv = null;
      } else {
        switch (toString.call(obj)) {
          case "[object Array]":
            // It's an array, create a new array with
            // deep copies of the entries
            rv = obj.map(deepCopy);
            break;
          case "[object Date]":
            // Clone the date
            rv = new Date(obj);
            break;
          case "[object RegExp]":
            // Clone the RegExp
            rv = new RegExp(obj);
            break;
          // ...probably a few others
          default:
            // Some other kind of object, deep-copy its
            // properties into a new object
            rv = Object.keys(obj).reduce(function (prev, key) {
              prev[key] = deepCopy(obj[key]);
              return prev;
            }, {});
            break;
        }
      }
      break;
    default:
      // It's a primitive, copy via assignment
      rv = obj;
      break;
  }
  return rv;
};

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { VictoryChart, VictoryStack, VictoryBar, VictoryAxis, VictoryContainer } from 'victory';
import SVG from 'react-inlinesvg';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { KgCO2e } from '../components/KgCO2e';
//util
import { colors, months, statsLinks, colorValues, completeUnits2, massUnits, volumesUnits } from '../util/values';
import { commonStyles } from '../util/styles';
import { getColor, sortBy, getMonthDate, getMonthDateShort, getWeekNumber, getFinancialYear, icon_TGV, icon_HSV, getIng, fromAnyToKg2, calculateCo2FromYield, getDateFromString, changeDateFrom, changeDateTo } from '../util/climatecook';

const StatsPurchasesScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [data, setData] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [monthItemList, setMonthItemList] = useState([]);
  const [monthsList, setMonthsList] = useState([""]);
  const [yearsList, setYearsList] = useState([""]);
  const [financialYearsList, setFinancialYearsList] = useState([""]);
  const [weeksList, setWeeksList] = useState([""]);
  const [periodList, setPeriodList] = useState([""]);
  const [dateFrom, setDateFrom] = useState(monthsList[0]);
  const [dateTo, setDateTo] = useState(new Date());
  const [categories, setCategories] = useState([""]);
  const [categories2, setCategories2] = useState([]);
  const [category, setCategory] = useState(categories[0]);
  const [maxCo2, setMaxCo2] = useState(0);
  const [totalCo2, setTotalCo2] = useState(0);
  const [improvement, setImprovement] = useState(0);
  const [improvementPercent, setImprovementPercent] = useState(0);
  const [tabs, setTabs] = useState(["lblByItem", "lblByMonths", "lblByYears", "lblByFinancialYears", "lblBy4Weeks"]);
  const [tab, setTab] = useState(tabs[0]);
  const [tickValuesX, setTickValuesX] = useState([]);
  const [tickFormatX, setTickFormatX] = useState([]);
  const [hover, setHover] = useState("");
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [financialYearMonth, setFinancialYearMonth] = useState(null);
  const [financialYearDay, setFinancialYearDay] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [ingredients, setIngredients] = useState([]);
  const firstUpdate = useRef(true);

  useEffect(() => {
    const financialYear = JSON.parse(localStorage.getItem('financialYear'));
    setFinancialYearMonth(financialYear && financialYear.financial_month != null ? financialYear.financial_month : 0);
    setFinancialYearDay(financialYear && financialYear.financial_day != null ? financialYear.financial_day : 1);
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getIngredients();
  }, [categories2]);

  useEffect(() => {
    getStats();
  }, [ingredients]);

  useEffect(() => {
    if (financialYearDay !== null && financialYearMonth !== null) {
      getCategories();
    }
  }, [financialYearDay, financialYearMonth]);

  useEffect(() => {
    changeDateFrom(dateFrom, dateTo, periodList, tab, setDateFrom, setDateTo);
  }, [periodList]);

  useEffect(() => {
    setCategory(categories[0]);
  }, [categories]);

  useEffect(() => {
    if (dateFrom != null && dateFrom !== '' && dateTo != null && dateTo !== '') {
      if (tab === "lblBy4Weeks" || tab === "lblByFinancialYears") {
        updateByWeeks();
      } else if (tab === "lblByItem") {
        updateByItems();
      } else {
        updateByMonths();
      }
    }
  }, [category, dateFrom, dateTo, forceUpdate]);

  useEffect(() => {
    if (tab === "lblBy4Weeks") {
      setPeriodList(weeksList);
    } else if (tab === "lblByFinancialYears") {
      setPeriodList(financialYearsList);
    } else if (tab === "lblByYears") {
      setPeriodList(yearsList);
    } else {
      setPeriodList(monthsList);
      changeDateFrom(dateFrom, dateTo, monthsList, tab, setDateFrom, setDateTo);
      setForceUpdate(prevState => prevState + 1);
    }
  }, [tab]);

  const handleMouseEnter = (which) => {
    setHover(which);
  };

  const handleMouseLeave = () => {
    setHover("");
  };

  const getCategories = async () => {
    const response = await aws.get('/categories', { params: { owner: user[0].user_id, isRecipe: 2 } });
    if (response.data) {
      response.data = response.data.filter((item) => item.id != null);
      setCategories2(response.data);
    }
  };

  const getIngredients = async () => {
    let cursor = null;
    let completeResponse = [];
    do {
      console.log('boucle', cursor);
      const response = await aws.get('/association', { params: { type: 'owner2', cursor, owner: user[0].user_id } });
      if (response.data) {
        completeResponse = [...completeResponse, ...response.data.ings];
        cursor = response.data.cursor;
      }
    } while (cursor != null);
    //const response = await aws.get('/ingredients/composition', { params: { isRecipe: 100, owner: user[0].user_id, isNullCategories: true, lang: i18n.language, region: 'CA' } });
    if (completeResponse) {
      let newFullIngredients = completeResponse;
      for (let i = 0; i < newFullIngredients.length; i++) {
        if (newFullIngredients[i].ing_level === 0) {
          let categoryName = null;
          newFullIngredients[i].thresholdHigh = colorValues.ingredientHigh;
          newFullIngredients[i].thresholdLow = colorValues.ingredientLow;
          for (let j = 0; j < categories2.length; j++) {
            if (newFullIngredients[i].food_group == categories2[j].id) {
              categoryName = i18n.language === "fr" ? categories2[j].name_fr : categories2[j].name_eng;
            }
          }
          newFullIngredients[i].category = categoryName;
        }
      }
      let newIngredients = [];
      for (let i = 0; i < newFullIngredients.length; i++) {
        if (newFullIngredients[i].ing_level === 0) {
          newIngredients.push(getIng(newFullIngredients, newFullIngredients[i], false));
        }
      }
      setIngredients(newIngredients);
    }
  };

  const getStats = async () => {
    if (ingredients.length === 0) {
      return;
    }
    const response = await aws.get('/stats/purchases', { params: { owner: user[0].user_id, dateFrom: '2010-01-01', dateTo: '2050-01-01' } });
    if (response.data) {
      /*let dups = [];
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].ing_level !== 1 && dups.some(o => o.main_ingredient_id === response.data[i].main_ingredient_id && o.ingredient_id === response.data[i].ingredient_id)) {
          response.data[i] = { remove: true };
        } else if (response.data[i].ing_level !== 1) {
          dups.push({ main_ingredient_id: response.data[i].main_ingredient_id, ingredient_id: response.data[i].ingredient_id });
        }
      }
      response.data = response.data.filter(o => !o.remove);*/

      let newIngredients = [];
      for (let i = 0; i < response.data.length; i++) {
        newIngredients.push({
          ingredient_id: response.data[i].ingredient_id,
          purchase_name: response.data[i].name,
          purchase_quantity: response.data[i].quantity,
          purchase_unit: response.data[i].unit,
          date: (response.data[i].date != null ? getDateFromString(response.data[i].date) : null),
          ...ingredients.find(o => o.main_ingredient_id === null && response.data[i].ingredient_id === o.ingredient_id),
        });
      }
      setData(newIngredients);

      let newCategories = ['offer'];
      let newMonths = [];
      let newYears = [];
      let newFinancialYears = [];
      let newWeeks = [];
      let financialYearsValues = [];
      let weekValues = [];
      for (let y = 2010; y < 2035; y++) {
        for (let i = 0; i < 13; i++) {
          let start = new Date(y, financialYearMonth, financialYearDay + (i * 28));
          let end = new Date(y, financialYearMonth, financialYearDay + 28 + (i * 28));
          if (i === 12) {
            end = new Date(y + 1, financialYearMonth, financialYearDay);
          }
          weekValues.push({ start, end, weekNb: i + 1, yearNb: y });
        }
        financialYearsValues.push({ start: new Date(y, financialYearMonth, financialYearDay), end: new Date(y + 1, financialYearMonth, financialYearDay), yearNb: y });
      }
      for (let item of newIngredients) {
        newMonths = addMonths(newMonths, item);
        newYears = addYears(newYears, item);
        newFinancialYears = addFinancialYears(newFinancialYears, item, financialYearsValues);
        newWeeks = addWeeks(newWeeks, item, weekValues);
        newCategories = addCategories(newCategories, item);
      }
      newMonths = newMonths.sort(sortBy('sortValue', false));
      newYears = newYears.sort(sortBy('sortValue', false));
      newFinancialYears = newFinancialYears.sort(sortBy('sortValue', false));
      newWeeks = newWeeks.sort(sortBy('sortValue', false));
      setMonthsList(newMonths);
      setYearsList(newYears);
      setFinancialYearsList(newFinancialYears);
      setWeeksList(newWeeks);
      setCategories(newCategories);
      setPeriodList(newMonths);
      setIngredients([]);
    }
  };

  const addMonths = (newMonths, item) => {
    let dateString = months[i18n.language][item.date.getMonth()] + " " + item.date.getFullYear();
    if (!newMonths.some(e => e.value === dateString)) {
      newMonths.push({ date: new Date(item.date.getFullYear(), item.date.getMonth()), value: dateString, sortValue: getMonthDate(item.date) });
    }
    return newMonths;
  };

  const addYears = (newYears, item) => {
    let dateString = item.date.getFullYear().toString();
    if (!newYears.some(e => e.value === dateString)) {
      newYears.push({ date: new Date(item.date.getFullYear(), 0), value: dateString, sortValue: dateString });
    }
    return newYears;
  };

  const addFinancialYears = (newWeeks, item, weekValues) => {
    for (let w of weekValues) {
      if (item.date.getTime() >= w.start.getTime() && item.date.getTime() < w.end.getTime()) {
        let dateString = w.yearNb.toString();
        if (!newWeeks.some(e => e.value === dateString)) {
          newWeeks.push({ dateStart: w.start, dateEnd: w.end, value: dateString, sortValue: dateString });
        }
        break;
      }
    }
    return newWeeks;
  };

  const addWeeks = (newWeeks, item, weekValues) => {
    for (let w of weekValues) {
      if (item.date.getTime() >= w.start.getTime() && item.date.getTime() < w.end.getTime()) {
        let dateString = w.yearNb.toString().substring(2) + "/" + (w.weekNb < 10 ? "0" + w.weekNb : w.weekNb);
        if (!newWeeks.some(e => e.value === dateString)) {
          newWeeks.push({ dateStart: w.start, dateEnd: w.end, value: dateString, sortValue: dateString, year: w.yearNb });
        }
        break;
      }
    }
    return newWeeks;
  };

  const addCategories = (newCategories, item) => {
    if (item.category != null && !newCategories.includes(item.category)) {
      newCategories.push(item.category);
    }
    return newCategories;
  };

  const updateByItems = () => {
    let usedIds = [];
    let usedNames = [];
    let newItemList = [];
    for (let item of data) {
      if (isInFilter(item)) {
        let quantityTokg = fromAnyToKg2(item.purchase_unit, item.purchase_quantity, item.yield_kg, item.yield_l, item.yield_unit);
        let calculatedCo2 = calculateCo2FromYield([{ unit: item.purchase_unit, quantity: item.purchase_quantity, yield_kg: item.yield_kg, yield_l: item.yield_l, yield_unit: item.yield_unit, portions: item.portions, co2: item.co2 }]);//item.co2 / item.yield_kg * quantityTokg;

        //if ((item.main_ingredient_id !== null && !usedIds.includes(item.main_ingredient_id)) || (item.main_ingredient_id === null && !usedNames.includes(item.name_eng))) {
        if (!usedIds.includes(item.ingredient_id)) {
          usedIds.push(item.ingredient_id);
          //if (item.main_ingredient_id !== null) {
          //  usedIds.push(item.main_ingredient_id);
          //} else if (item.main_ingredient_id === null) {
          //  setIsIncomplete(true);
          //  usedNames.push(item.name_eng);
          //}

          newItemList.push({
            ingredient_id: item.ingredient_id,
            name: i18n.language === 'fr' ? item.name_fra : item.name_eng,
            vendor: item.vendor,
            category: item.category,
            yieldKg: item.yield_kg,
            quantity: quantityTokg,
            shownQuantity: item.purchase_quantity,
            unit: item.purchase_unit,
            shownUnit: t(completeUnits2[item.purchase_unit].code),
            co2: item.co2,
            total_co2: item.co2 === null ? NaN : calculatedCo2 === Infinity ? NaN : calculatedCo2,
            threshold_low: colorValues.ingredientLow,
            threshold_high: colorValues.ingredientHigh,
          });
        } else {
          for (let item2 of newItemList) {
            if (item2.ingredient_id === item.ingredient_id) {
              item2.quantity += quantityTokg;
              item2.shownQuantity = item2.quantity;
              item2.total_co2 += calculatedCo2 === Infinity ? NaN : calculatedCo2;
              item2.shownUnit = t(completeUnits2['kg'].code);
            }
          }
        }
      }
    }

    let newMaxCo2 = 0;
    let newTotalCo2 = 0;
    for (let item of newItemList) {
      newTotalCo2 += isNaN(item.total_co2) ? 0 : item.total_co2;
      if (newMaxCo2 < item.total_co2) {
        newMaxCo2 = item.total_co2;
      }
    }

    newItemList = newItemList.sort(sortBy('total_co2', false, false, Number));
    setMaxCo2(newMaxCo2);
    setTotalCo2(newTotalCo2 / 1000);
    setItemList(newItemList);
    setImprovement(0);
    setImprovementPercent(0);
  };

  const updateByMonths = () => {
    let usedMonths = [];
    let newTickValuesX = [];
    let newMonthItemList = [];
    for (let item of data) {
      if (item.ingredient_id !== null && isInFilter(item)) {
        let dateShort = tab === 'lblByMonths' ? getMonthDateShort(item.date) : item.date.getFullYear().toString();
        if (!usedMonths.includes(dateShort)) {
          newTickValuesX.push(newTickValuesX.length === 0 ? 1 : (newTickValuesX[newTickValuesX.length - 1] + 1));
          usedMonths.push(dateShort);
          newMonthItemList.push({ date: item.date, time: item.date.getTime(), month: dateShort, ...calculateColors(item) });
        } else {
          for (let item2 of newMonthItemList) {
            if (item2.month === dateShort) {
              let co2 = calculateColors(item);
              item2.green += co2.green;
              item2.yellow += co2.yellow;
              item2.red += co2.red;
            }
          }
        }
      } else if (item.ingredient_id === null) {
        setIsIncomplete(true);
      }
    }
    for (let item of newMonthItemList) {
      if (item.red > 0) {
        item.corner = 'red';
      } else if (item.yellow > 0) {
        item.corner = 'yellow';
      } else {
        item.corner = 'green';
      }
    }

    usedMonths = [];
    newMonthItemList = newMonthItemList.sort(sortBy('time', true, false, Number));
    for (let item of newMonthItemList) {
      usedMonths.push(item.month);
    }


    let newTotalCo2 = 0;
    let firstMonthCo2 = 0;
    let lastMonthCo2 = 0;
    for (let i = 0; i < newMonthItemList.length && i < 12; i++) {
      let sum = newMonthItemList[i].green + newMonthItemList[i].yellow + newMonthItemList[i].red;
      newMonthItemList[i].label = sum.toFixed(2);
      newTotalCo2 += sum;

      if (i === 0) {
        firstMonthCo2 = sum;
      }
      if (i === newMonthItemList.length - 1 || i === 11) {
        lastMonthCo2 = sum;
      }
    }

    //show label of only 1 month out of 2
    /*if (usedMonths.length > 8) {
      for (let i = 0; i < usedMonths.length; i++) {
        if (i % 2 !== 0 && (usedMonths.length % 2 === 0 || usedMonths.length % 2 !== 0)) {
          usedMonths[i] = "";
        }
      }
    }*/
    newTickValuesX.splice(12);
    newMonthItemList.splice(12);

    setTotalCo2(newTotalCo2);
    setImprovementPercent(((firstMonthCo2 - lastMonthCo2) / firstMonthCo2) * 100);
    setImprovement(firstMonthCo2 - lastMonthCo2);
    setTickValuesX(newTickValuesX);
    setTickFormatX(usedMonths);
    setMonthItemList(newMonthItemList);
  };

  const updateByWeeks = () => {
    let usedWeeks = [];
    let newTickValuesX = [];
    let newWeeksItemList = [];
    for (let item of data) {
      if (item.ingredient_id !== null && isInFilter(item)) {
        let dateShort = tab === 'lblBy4Weeks' ? getWeekNumber(weeksList, item.date) : getFinancialYear(financialYearsList, item.date).toString();
        if (!usedWeeks.includes(dateShort)) {
          newTickValuesX.push(newTickValuesX.length === 0 ? 1 : (newTickValuesX[newTickValuesX.length - 1] + 1));
          usedWeeks.push(dateShort);
          newWeeksItemList.push({ date: item.date, time: item.date.getTime(), month: dateShort, ...calculateColors(item) });
        } else {
          for (let item2 of newWeeksItemList) {
            if (item2.month === dateShort) {
              let co2 = calculateColors(item);
              item2.green += co2.green;
              item2.yellow += co2.yellow;
              item2.red += co2.red;
            }
          }
        }
      } else if (item.ingredient_id === null) {
        setIsIncomplete(true);
      }
    }
    for (let item of newWeeksItemList) {
      if (item.red > 0) {
        item.corner = 'red';
      } else if (item.yellow > 0) {
        item.corner = 'yellow';
      } else {
        item.corner = 'green';
      }
    }

    usedWeeks = [];
    newWeeksItemList = newWeeksItemList.sort(sortBy('month', true));
    for (let item of newWeeksItemList) {
      usedWeeks.push(item.month);
    }

    let newTotalCo2 = 0;
    let firstMonthCo2 = 0;
    let lastMonthCo2 = 0;
    for (let i = 0; i < newWeeksItemList.length && i < 13; i++) {
      let sum = newWeeksItemList[i].green + newWeeksItemList[i].yellow + newWeeksItemList[i].red;
      newWeeksItemList[i].label = sum.toFixed(2);
      newTotalCo2 += sum;

      if (i === 0) {
        firstMonthCo2 = sum;
      }
      if (i === newWeeksItemList.length - 1 || i === 12) {
        lastMonthCo2 = sum;
      }
    }

    //show label of only 1 month out of 2
    /*if (usedWeeks.length > 8) {
      for (let i = 0; i < usedWeeks.length; i++) {
        if (i % 2 !== 0 && (usedWeeks.length % 2 === 0 || usedWeeks.length % 2 !== 0)) {
          usedWeeks[i] = "";
        }
      }
    }*/
    newTickValuesX.splice(13);
    newWeeksItemList.splice(13);

    setTotalCo2(newTotalCo2);
    setImprovementPercent(((firstMonthCo2 - lastMonthCo2) / firstMonthCo2) * 100);
    setImprovement(firstMonthCo2 - lastMonthCo2);
    setTickValuesX(newTickValuesX);
    setTickFormatX(usedWeeks);
    setMonthItemList(newWeeksItemList);
  };

  const calculateColors = (item) => {
    let obj = { green: 0, yellow: 0, red: 0 };
    let quantityTokg = fromAnyToKg2(item.purchase_unit, item.purchase_quantity, item.yield_kg, item.yield_l, item.yield_unit);
    let averageCo2 = item.co2 === null ? 0 : item.co2 / item.yield_kg * quantityTokg;
    //let itemForCo2 = { name_fra: item.name_fra, co2: item.co2, unit: item.purchase_unit, quantity: item.purchase_quantity, yield_kg: item.yield_kg, yield_l: item.yield_l, yield_unit: item.yield_unit };
    //let averageCo2 = item.co2 === null || item.main_ingredient_id === null ? 0 : calculateCo2FromYield([itemForCo2]);//item.co2 / 1;
    if (item.co2 < colorValues.ingredientLow) {
      obj.green = averageCo2 / 1000;
    } else if (item.co2 >= colorValues.ingredientHigh) {
      obj.red = averageCo2 / 1000;
    } else {
      obj.yellow = averageCo2 / 1000;
    }
    return obj;
  };

  const isInFilter = (item) => {
    let date = item.date.getTime();
    let dateFromTime;
    let dateToTime;
    if (tab === 'lblBy4Weeks' && dateFrom.dateStart) {
      dateFromTime = (new Date(dateFrom.dateStart.getFullYear(), dateFrom.dateStart.getMonth(), dateFrom.dateStart.getDate())).getTime();
      dateToTime = (new Date(dateTo.dateEnd.getFullYear(), dateTo.dateEnd.getMonth(), dateTo.dateEnd.getDate())).getTime();
    } else if (tab === 'lblByFinancialYears' && dateFrom.dateStart) {
      dateFromTime = (new Date(dateFrom.dateStart.getFullYear(), dateFrom.dateStart.getMonth(), dateFrom.dateStart.getDate())).getTime();
      dateToTime = (new Date(dateTo.dateEnd.getFullYear(), dateTo.dateEnd.getMonth(), dateTo.dateEnd.getDate())).getTime();
    } else if (tab === 'lblByYears' && dateFrom.date) {
      dateFromTime = (new Date(dateFrom.date.getFullYear(), 0)).getTime();
      dateToTime = (new Date(dateTo.date.getFullYear() + 1, 0)).getTime();
    } else if (dateFrom.date) {
      dateFromTime = (new Date(dateFrom.date.getFullYear(), dateFrom.date.getMonth())).getTime();
      dateToTime = (new Date(dateTo.date.getFullYear(), dateTo.date.getMonth() + 1)).getTime();
    }

    if (item.co2 === null && item.ingredient_id !== null) {
      return false;
    }

    if (date < dateFromTime || date >= dateToTime) {
      return false;
    }

    let categoryName = i18n.language === 'fr' ? item.cat_name_fr : item.cat_name_en;
    if (category !== 'offer' && categoryName !== category) {
      return false;
    }
    return true;
  };

  const cornerRadius = (item, corner) => {
    if (item.corner === corner) {
      return 8;
    }
    return 0;
  };

  const renderValue = (value, type) => {
    if (value === 'offer') {
      return t('lblAll' + type);
    } else if (value === 'null') {
      return t('lblNull' + type);
    }
    return value;
  };

  const getWidth = () => {
    if (monthItemList.length < 7) {
      return 60;
    } else if (monthItemList.length < 11) {
      return 40;
    } else {
      return 20;
    }
  };

  const renderItemList = (itemList) => {
    if (tab === "lblByItem") {
      return (
        <div>
          <table style={styles.table}>
            <tbody>
              <tr key="header">
                <th style={{ ...styles.headerCell, width: 200 }}>{t('lblPurchasesImpact')}</th>
                <th style={{ ...styles.headerCell, width: 200 }}>{t('lblQtyPurchased')}</th>
                <th style={styles.headerCell}>{t('lblName')}</th>
                <th></th>
              </tr>
              {itemList.map((item) => {
                let itemCo2 = item.co2 / item.yieldKg;
                let itemColor = getColor(/*isNaN(item.total_co2) ? null : */itemCo2, 1, item.threshold_high, item.threshold_low);
                return ([
                  <tr key={item.ingredient_id + "id"}>
                    <td style={{ ...styles.cell, display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: 10 }}>{item.total_co2.toFixed(2).replace(/[.,]00$/, "")}</div>
                      <KgCO2e size={16} color={colors.black} isAlone />
                    </td>
                    <td style={styles.cell}>{item.shownQuantity.toFixed(2).replace(/[.,]00$/, "")} {item.shownUnit}</td>
                    <td style={{ ...styles.cell, display: 'flex' }}>
                      <div style={{ marginRight: 10 }}>{item.name}{item.vendor != null ? ' - ' + item.vendor : ''}</div>
                      {item.category !== null ? <div style={{ color: colors.text, fontWeight: 400 }}>({item.category})</div> : null}
                    </td>
                  </tr>,
                  <tr key={item.ingredient_id + "color"}>
                    <td colSpan="3" style={{ padding: 0 }}>
                      <div style={{ width: (maxCo2 > 0 ? item.total_co2 * 100 / maxCo2 : 0) + "%", height: 26, backgroundColor: itemColor }}></div>
                    </td>
                  </tr>
                ]);
              })}
            </tbody>
          </table>
        </div>
      );
    } else if (monthItemList.length > 0) {
      return (
        <div style={{ width: 700 }}>
          <div style={{ margin: '30px 0 -20px 0', fontSize: 12 }}>
            <div style={{ fontFamilly: 'Inter', display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 11, fontWeight: 400, color: colors.text }}>
              <div>tCO</div>
              <div style={{ fontSize: 7, alignSelf: 'flex-end' }}>2</div>
              <div>e</div>
            </div>
          </div>
          <VictoryChart width={720} height={450} domain={{ x: [0, tickValuesX.length + 1] }} domainPadding={{ x: [0, 0], y: 0 }}
            containerComponent={<VictoryContainer responsive={false} />}>
            <VictoryStack colorScale={[colors.good, colors.warning, colors.bad]} >
              <VictoryBar style={{ ...styles.victoryBar, data: { width: getWidth() } }} data={monthItemList} x="month" y="green" cornerRadius={{ topLeft: ({ datum }) => cornerRadius(datum, 'green'), topRight: ({ datum }) => cornerRadius(datum, 'green') }} labelComponent={<></>} />
              <VictoryBar style={{ ...styles.victoryBar, data: { width: getWidth() } }} data={monthItemList} x="month" y="yellow" cornerRadius={{ topLeft: ({ datum }) => cornerRadius(datum, 'yellow'), topRight: ({ datum }) => cornerRadius(datum, 'yellow') }} labelComponent={<></>} />
              <VictoryBar style={{ ...styles.victoryBar, data: { width: getWidth() } }} data={monthItemList} x="month" y="red" cornerRadius={{ topLeft: ({ datum }) => cornerRadius(datum, 'red'), topRight: ({ datum }) => cornerRadius(datum, 'red') }} />
            </VictoryStack>
            <VictoryAxis
              style={styles.victoryAxisX}
              tickValues={tickValuesX}
              tickFormat={tickFormatX}
            />
            <VictoryAxis
              style={styles.victoryAxisY}
              dependentAxis
            />
          </VictoryChart>
        </div >
      );
    }
  };

  return (
    <div style={commonStyles.container}>
      <div style={commonStyles.title}>{t('routeStatsPurchases')}</div>
      <div style={{ ...commonStyles.body, maxWidth: 1200 }}>
        <div style={styles.otherContainer}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={styles.topContainer}>
              <table style={{ width: 0, borderCollapse: 'separate', borderSpacing: '0 20px', tableLayout: 'fixed' }}>
                <tbody>
                  <tr>
                    <td style={styles.cellOne}>{t('lblPeriod')}</td>
                    <td style={styles.cellTwo}>
                      <Select sx={styles.unit} variant="standard" value={dateFrom} onChange={(event) => changeDateFrom(event.target.value, dateTo, periodList, tab, setDateFrom, setDateTo)}>
                        {periodList.map(item => <MenuItem key={item.value + "from"} value={item}>{item.value}</MenuItem>)}
                      </Select>
                    </td>
                    <td style={styles.cellThree}>{t('lblTo')}</td>
                    <td style={styles.cellTwo}>
                      <Select sx={styles.unit} variant="standard" value={dateTo} onChange={(event) => changeDateTo(event.target.value, dateFrom, periodList, tab, setDateFrom, setDateTo)}>
                        {periodList.map(item => <MenuItem key={item.value + "to"} value={item}>{item.value}</MenuItem>)}
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.cellOne}>{t('lblCategory')}</td>
                    <td style={styles.cellTwo}>
                      <Select sx={styles.unit} variant="standard" value={category} onChange={(event) => setCategory(event.target.value)}>
                        {categories.map(item => <MenuItem key={item + "cat"} value={item}>{renderValue(item, 'Categories')}</MenuItem>)}
                      </Select>
                    </td>
                    <td style={styles.cellThree}></td>
                    <td style={styles.cellTwo}>
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.cellOne}>{t('lblVisualisation')}</td>
                    <td colSpan="3" style={{ padding: 0 }}>
                      <Select sx={styles.unit} variant="standard" value={tab} onChange={(event) => setTab(event.target.value)}>
                        {tabs.map(item => <MenuItem key={item} value={item}>{t(item)}</MenuItem>)}
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" style={{ padding: 0 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 20 }}>{t('lblTotalImpactPurchases')}</div>
                        <div style={{ fontSize: 22 }}>{totalCo2.toFixed(3)} tCO</div>
                        <div style={{ fontSize: 16, alignSelf: 'flex-end' }}>2</div>
                        <div style={{ fontSize: 22 }}>e</div>
                      </div>
                    </td>
                  </tr>
                  {tab !== "lblByItem" && monthItemList.length > 0 ? <tr>
                    <td colSpan="4" style={{ padding: 0 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 20 }}>{t('lblImproveImpact')}</div>
                        <div style={{ fontSize: 22, color: colors.green }}>{improvementPercent.toFixed(0)}%</div>
                      </div>
                    </td>
                  </tr> : null}
                  {isIncomplete ? <tr>
                    <td colSpan="4" style={{ padding: 0 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ color: colors.bad }}>{t('lblIncomplete')}</div>
                      </div>
                    </td>
                  </tr> : null}
                </tbody>
              </table>
            </div>
            {renderItemList(itemList)}
          </div>
          {tab !== "lblByItem" && monthItemList.length > 0 ? <div style={styles.otherContainer2}>
            <div style={styles.lblGain}>{t(tab === 'lblByMonths' ? 'lblMonthlyGain' : 'lblAnnualGain')} <div style={{ marginLeft: 10, color: colors.black }}>{improvement.toFixed(3)} tCO</div><div style={{ fontSize: 16, alignSelf: 'flex-end', color: colors.black }}>2</div><div style={{ color: colors.black }}>e</div></div>
            <div style={{ ...styles.lblGain, marginBottom: 40 }}>{t('lblEquivalent')}</div>
            <div style={styles.infoContainer}>
              <SVG src={i18n.language === 'fr' ? icon_TGV(colors.main) : icon_HSV(colors.main)} width={120} />
              <div style={styles.infoText}>{t('lblHSV', { nb: ((improvement * 1000) / 0.00173 / 40075).toFixed(1) })}<sup>1</sup></div>
            </div>
            <div style={styles.infoContainer}>
              <img src={require('../assets/trees.png')} style={{ width: 100 }} />
              <div style={styles.infoText}>{t('lblTrees', { nb: ((improvement * 1000) * 2.20462262185 / 48).toFixed(1) })}<sup>2</sup></div>
            </div>
            <div style={styles.infoContainer}>
              <img src={require('../assets/car.png')} style={{ width: 100 }} />
              <div style={styles.infoText}>{t('lblCar', { nb: ((improvement * 1000) / 0.170).toFixed(1) })}<sup>3</sup></div>
            </div>
            <div style={styles.links}>
              <div style={{ display: 'flex' }}>
                <a style={{ ...styles.linkText, color: hover === statsLinks[0] ? colors.darkGray : colors.main }} onMouseEnter={() => handleMouseEnter(statsLinks[0])} onMouseLeave={handleMouseLeave} href={statsLinks[0]} target="_blank" rel="noopener noreferrer">1 {t('lblLinkHSV')}</a>
                <div style={{ marginRight: 3, color: colors.main }}>,</div>
                <a style={{ ...styles.linkText, color: hover === statsLinks[1] ? colors.darkGray : colors.main }} onMouseEnter={() => handleMouseEnter(statsLinks[1])} onMouseLeave={handleMouseLeave} href={statsLinks[1]} target="_blank" rel="noopener noreferrer">{t('lblLinkWorld')}</a>
              </div>
              <a style={{ ...styles.linkText, color: hover === statsLinks[2] ? colors.darkGray : colors.main }} onMouseEnter={() => handleMouseEnter(statsLinks[2])} onMouseLeave={handleMouseLeave} href={statsLinks[2]} target="_blank" rel="noopener noreferrer">2 {t('lblLinkTrees')}</a>
              <a style={{ ...styles.linkText, color: hover === statsLinks[3] ? colors.darkGray : colors.main }} onMouseEnter={() => handleMouseEnter(statsLinks[3])} onMouseLeave={handleMouseLeave} href={statsLinks[3]} target="_blank" rel="noopener noreferrer">3 {t('lblLinkCar')}</a>
            </div>
          </div> : null}
        </div>
      </div>
    </div>
  );
};

const styles = {
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  },
  cellOne: {
    padding: 0,
    width: 120
  },
  cellTwo: {
    padding: 0,
    width: 150,
  },
  cellThree: {
    padding: 0,
    width: 100,
    textAlign: 'center'
  },
  otherContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 20px 0 20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0'
  },
  headerCell: {
    padding: '0 20px 0 0',
    fontWeight: 400,
    textAlign: 'left',
    color: colors.text
  },
  cell: {
    padding: '14px 20px 4px 0',
    fontSize: 16,
    fontWeight: 500,
  },
  unit: {
    margin: 0,
    fontFamily: 'Inter',
    width: '100%',
    fontSize: 15,
    fontWeight: 400,
    color: colors.textNegative,
    textAlign: 'center',
    backgroundColor: colors.main,
    borderRadius: 2.5,
    transition: 'background-color 0.1s linear',
    '&:hover': { backgroundColor: colors.darkGray },
    '&:before': { border: 'none' },
    '&:after': { border: 'none' },
    '&:not(.Mui-disabled):hover::before': { border: 'none' },
    '.MuiSelect-icon': { display: 'none' },
    '.MuiSelect-select': { padding: '5px 20px !important', width: '100%' }
  },
  otherContainer2: {
    width: 366,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    alignItems: 'center',
    marginTop: 150
  },
  lblGain: {
    display: 'flex',
    alignSelf: 'flex-start',
    marginBottom: 10,
    fontSize: 22,
    fontWeight: 600,
    color: colors.main
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 60,
  },
  infoText: {
    marginTop: 10,
    color: colors.main
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
  },
  linkText: {
    marginTop: 4,
    textDecoration: 'none',
    fontSize: 12
  },


  victoryAxisX: {
    axis: { stroke: colors.text, strokeWidth: 0.5 },
    tickLabels: { fontSize: 14, padding: 5, fill: colors.text }
  },
  victoryAxisY: {
    axis: { stroke: "transparent" },
    ticks: { stroke: colors.lightGrey, size: 5 },
    tickLabels: { fontSize: 14, padding: 5, fill: colors.text }
  },
  victoryBar: {
    data: { width: 60 },
    labels: {
      fontSize: 14,
      fill: colors.text
    }
  }
};

export default StatsPurchasesScreen;
